import { useEffect } from "react";
import React from "react";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import SignInBasic from "pages/LandingPages/SignIn";
import SignUpBasic from "pages/LandingPages/SignUp";
import AdminDashboard from "pages/Admin-Dashboard";

export default function App() {
  const { pathname } = useLocation();
  //Logged in flag
  const [loggedIn, setLoggedIn] = React.useState(undefined);
  const [userType, SetUserType] = React.useState("");
  const [userDetails, SetUserDetails] = React.useState([]);
  const [SignupSuccess, SetSignupSuccess] = React.useState();
  // console.log("setLoggedIn App:", setLoggedIn);
  console.log("Signed up:", SignupSuccess);
  useEffect(() => {
    if (loggedIn == undefined) {
      setLoggedIn(JSON.parse(window.localStorage.getItem("Logged_In")));
      SetUserType(window.localStorage.getItem("User_Type"));
      SetUserDetails(JSON.parse(window.localStorage.getItem("user_details")));
    }
  }, [loggedIn]);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  useEffect(() => {
    // console.log("logedin changed:", loggedIn);
    if (loggedIn != undefined) window.localStorage.setItem("Logged_In", JSON.stringify(loggedIn));
    else window.localStorage.setItem("Logged_In", JSON.stringify(false));

    if (userType != "") window.localStorage.setItem("User_Type", userType);
    if (userDetails != []) window.localStorage.setItem("user_details", JSON.stringify(userDetails));
  }, [loggedIn, userType, userDetails]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {loggedIn ? (
          <>
            {userType == "User" ? (
              <>
                {getRoutes(routes)}
                <Route
                  path="/Home"
                  element={
                    <Presentation
                      setLoggedIn={setLoggedIn}
                      SetUserType={SetUserType}
                      SetUserDetails={SetUserDetails}
                    />
                  }
                />
                <Route path="*" element={<Navigate to="Home" />} />{" "}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Route
              path="/sign-in"
              element={
                <SignInBasic
                  SetUserType={SetUserType}
                  SetUserDetails={SetUserDetails}
                  setLoggedIn={setLoggedIn}
                />
              }
            />
            <Route
              path="/sign-up"
              element={
                <SignUpBasic
                  SetUserDetails={SetUserDetails}
                  SetUserType={SetUserType}
                  setLoggedIn={setLoggedIn}
                />
              }
            />
            <Route path="*" element={<Navigate to="sign-in" />} />
          </>
        )}
      </Routes>
    </ThemeProvider>
  );
}
