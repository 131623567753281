import React, { useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import axios from "axios";
import AttachFile from "@mui/icons-material/AttachFile";
import AddAlert from "@mui/icons-material/AddAlert";

// Images
import bgImage from "assets/images/examples/blog2.jpg";
import CustomFileInput from "./CustomFileInput";
import { Domain } from "Domain";
import SingleSelect from "./SingleSelect";
import Success from "assets/images/success.gif";
import MKAvatar from "components/MKAvatar";
import MKBadge from "components/MKBadge";

function Contact() {
  const [validated, setValidated] = React.useState(true);
  const [files, setFiles] = React.useState(null);
  const [userDetails, SetUserDetails] = React.useState([]);
  const [Developers, setDevelopers] = React.useState([]);
  const [ticketnumber, setTicketNumber] = React.useState();
  useEffect(() => {
    SetUserDetails(JSON.parse(window.localStorage.getItem("user_details")));
  }, []);
  // console.log("user detailsss:", userDetails);

  //Form Data
  const [data, setData] = React.useState({
    Id: 0,
    Userid: "1",
    FullName: "",
    Project: "",
    Email: "",
    Subject: "",
    Description: "",
    Image: "",
  });
  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log("Data", newData);
  }
  //function to upload
  function UploadImage() {
    if (files != null) {
      let formData = new FormData();
      formData.append("Image", files[0]);
      // console.log(formData);
      axios({
        method: "post",
        url: Domain + "/upload_file.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("File Upload Response:", response.data);

          data.Image = response.data;
          HandleSave();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      HandleSave();
    }
  }
  //Function for Validating fields
  function ValidateFields() {
    if (data.FullName == "") {
      return false;
    } else if (DeveloperValues.Id == null) {
      return false;
    } else if (data.Subject == "") {
      return false;
    } else if (data.Description == "") {
      return false;
    } else return true;
  }
  const [saved, setSaved] = React.useState(false);

  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      // setTimeout(function () {
      //   setSaved(false);
      // }, 3000);
    }
  };
  //Function to save data to database
  function HandleSave() {
    if (ValidateFields()) {
      setValidated(true);
      let formData = new FormData();
      formData.append("Id", data.Id);
      formData.append("Userid", userDetails.id);
      formData.append("FullName", data.FullName);
      formData.append("Project", DeveloperValues.label);
      formData.append("Email", userDetails.Email);
      formData.append("Subject", data.Subject);
      formData.append("Description", data.Description);
      formData.append("Image", data.Image);
      formData.append("Type", "2");
      axios({
        method: "post",
        url: Domain + "/raise_ticket.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          if (response.data.success) {
            setData({
              Id: 0,
              FullName: "",
              Project: "",
              Subject: "",
              Description: "",
              Image: "",
            });
            console.log("saved");
            setTicketNumber(response.data.data);
            showSavedNotification();
          } else {
            console.log("failed");
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      setValidated(false);
    }
  }

  useEffect(() => {
    let formData = new FormData();
    formData.append("Status", "Active");

    axios({
      method: "post",
      url: Domain + "/GetAllProjects.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            console.log("Success");
            console.log("Projects:", response.data.data);
            setDevelopers(response.data.data);
          } else {
            console.log("failed");
          }
        } else {
          console.log("Projects empty");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  const DevelopersList = Developers.map((d) => ({
    value: d.id,
    label: d.ProjectName,
  }));
  const [DeveloperValues, setDevelopersValue] = React.useState({
    Id: null,
    label: "",
  });
  useEffect(() => {
    console.log("Selected:", DeveloperValues);
  }, [DeveloperValues.Id, DeveloperValues]);
  return (
    <>
      {/* <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Ticket Submited Successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      /> */}
      {saved ? (
        <>
          {" "}
          <MKBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
              <MKAvatar src={Success} alt="xxl" size="xxl" />
              <MKTypography variant="h3" color="text" mb={1}>
                Ticket Submitted Successfully!
              </MKTypography>
              <MKTypography variant="body2" color="text" opacity={0.8} mb={3}>
                Your Ticket number is{" "}
                <MKTypography variant="h2" color="success" mb={3}>
                  {ticketnumber}
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </>
      ) : (
        <>
          <MKBox component="section" py={{ xs: 0, lg: 6 }}>
            <Container>
              <Grid container item>
                <MKBox
                  mt={5}
                  width="100%"
                  bgColor="white"
                  borderRadius="xl"
                  shadow="xl"
                  mb={6}
                  sx={{ overflow: "hidden" }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      lg={5}
                      position="relative"
                      px={0}
                      sx={{
                        backgroundImage: ({
                          palette: { gradients },
                          functions: { rgba, linearGradient },
                        }) =>
                          `${linearGradient(
                            rgba(gradients.dark.main, 0.8),
                            rgba(gradients.dark.state, 0.8)
                          )}, url(${bgImage})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="100%"
                      >
                        <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                          <MKTypography variant="h3" color="white" mb={1}>
                            Submit a Ticket
                          </MKTypography>
                          <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                            Fill up the form and our Team will get back to you !
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <MKBox component="form" p={2} method="post">
                        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                          <MKTypography variant="h2" mb={1}>
                            Say Hi!
                          </MKTypography>
                          <MKTypography variant="body1" color="text" mb={2}>
                            Ticket Informations.
                          </MKTypography>
                        </MKBox>
                        <MKBox pt={0.5} pb={3} px={3}>
                          <Grid container>
                            <Grid item xs={12} pr={1} mb={6}>
                              <SingleSelect
                                noOptionsMessage="No projects available"
                                placeholder="Select Project"
                                Options={DevelopersList}
                                setValue={setDevelopersValue}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={6}>
                              <MKInput
                                variant="standard"
                                onChange={(e) => HandleData(e)}
                                value={data.FullName}
                                id="FullName"
                                label="My name is"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} pr={1} mb={6}>
                              <MKInput
                                variant="standard"
                                onChange={(e) => HandleData(e)}
                                value={data.Subject}
                                id="Subject"
                                label="Subject"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={6}>
                              <MKInput
                                variant="standard"
                                onChange={(e) => HandleData(e)}
                                value={data.Description}
                                id="Description"
                                label="Decription"
                                fullWidth
                                multiline
                                rows={6}
                              />
                            </Grid>
                            <Grid item xs={12} pr={1} mb={6}>
                              <CustomFileInput
                                setFiles={setFiles}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "Click here to upload an image",
                                }}
                                endButton={{
                                  buttonProps: {
                                    round: true,
                                    color: "info",
                                    justIcon: true,
                                  },
                                  icon: <AttachFile />,
                                }}
                              />
                            </Grid>
                          </Grid>
                          {validated ? (
                            <></>
                          ) : (
                            <>
                              {" "}
                              <MKTypography variant="body1" color="error" mb={2}>
                                Please fill up all the details
                              </MKTypography>
                            </>
                          )}
                          <Grid
                            container
                            item
                            xs={12}
                            md={6}
                            justifyContent="flex-end"
                            textAlign="right"
                            ml="auto"
                          >
                            <MKButton variant="gradient" color="info" onClick={UploadImage}>
                              Raise Ticket
                            </MKButton>
                          </Grid>
                        </MKBox>
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
        </>
      )}
    </>
  );
}

export default Contact;
