import React from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//components
import Box from "components/MKBox";
import Typography from "components/MKTypography";
import Input from "components/MKInput";
import Button from "components/MKButton";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Domain } from "Domain";

function SignUpBasic({ SetUserDetails, SetUserType, setLoggedIn }) {
  const [validated, setValidated] = React.useState(true);
  const [emailError, setEmailError] = React.useState(false);
  const [PasswordSuccess, setPasswordSuccess] = React.useState(false);
  const [PasswordError, setPasswordError] = React.useState(false);
  const [ConfPasswordError, setConfPasswordError] = React.useState(false);
  //Data variable
  const [data, setData] = React.useState({
    Id: 0,
    FullName: "",
    Mobile: "",
    Email: "",
    Password: "",
    Usertype: "User",
  });
  //Handle data function
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  //Function for Validating fields
  function ValidateFields() {
    if (data.FullName == "") {
      return false;
    } else if (data.Mobile == "") {
      return false;
    } else if (data.Email == "") {
      return false;
    } else if (data.Password == "") {
      return false;
    } else return true;
  }

  //Function to save Data
  function HandleSave() {
    if (ValidateFields()) {
      setValidated(true);
      let formData = new FormData();
      formData.append("Id", data.Id);
      formData.append("FullName", data.FullName);
      formData.append("Mobile", data.Mobile);
      formData.append("Email", data.Email);
      formData.append("Password", data.Password);
      formData.append("Usertype", data.Usertype);
      axios({
        method: "post",
        url: Domain + "/saveData.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Save Data", response);
          if (response.data.success) {
            setData({
              Id: 0,
              FullName: "",
              Mobile: "",
              Email: "",
              Password: "",
              Usertype: "User",
            });
            console.log("saved");
            setLoggedIn(true);
            SetUserType("User");
            SetUserDetails(response.data.data);
          } else {
            console.log("failed");
          }
        })
        .catch(function (response) {
          //handle error
          setDeleted(true);
          console.log(response);
        });
    } else {
      setValidated(false);
    }
  }
  //===========================================//
  return (
    <>
      <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <Box
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Create New Account
                </Typography>
              </Box>
              <Box pt={4} pb={3} px={3}>
                <Box component="form" role="form">
                  <Box mb={2}>
                    <Input
                      type="text"
                      label="Full Name"
                      fullWidth
                      onChange={(e) => HandleData(e)}
                      value={data.FullName}
                      id="FullName"
                    />
                  </Box>
                  <Box mb={2}>
                    <Input
                      type="email"
                      label="Email"
                      fullWidth
                      onChange={(e) => HandleData(e)}
                      value={data.Email}
                      id="Email"
                    />
                  </Box>
                  <Box mb={2}>
                    <Input
                      error={emailError}
                      type="text"
                      label="Mobile"
                      fullWidth
                      onChange={(e) => HandleData(e)}
                      value={data.Mobile}
                      id="Mobile"
                    />
                  </Box>
                  <Box mb={2}>
                    <Input
                      success={PasswordSuccess}
                      error={PasswordError}
                      type="password"
                      label="New Password"
                      fullWidth
                      onChange={(e) => HandleData(e)}
                      value={data.Password}
                      id="Password"
                    />
                  </Box>
                  <Box mb={2}>
                    <Input
                      error={ConfPasswordError}
                      success={PasswordSuccess}
                      type="password"
                      label="Confirm Password"
                      fullWidth
                      onChange={(e) => HandleData(e)}
                      value={data.ConfPassword}
                      id="ConfPassword"
                    />
                  </Box>

                  {validated ? (
                    <></>
                  ) : (
                    <Typography variant="button" color="error">
                      Please fill all the data to proceed
                    </Typography>
                  )}
                  <Box mt={4} mb={1}>
                    <Button variant="gradient" color="info" fullWidth onClick={HandleSave}>
                      Sign Up
                    </Button>
                  </Box>
                  <Box mt={3} mb={1} textAlign="center">
                    <Typography variant="button" color="text">
                      Already have an account?{" "}
                      <Typography
                        component={Link}
                        to="/authentication/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign In
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box width="100%" position="absolute" zIndex={2} bottom="1.625rem"></Box>
    </>
  );
}

export default SignUpBasic;
