// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import MKBadge from "components/MKBadge";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

function DefaultReviewCard({ id, color, image, name, date, description, project, status }) {
  const statuses = {
    Raised: [
      <MKBadge
        variant="contained"
        color="warning"
        badgeContent="Raised"
        container
        sx={{ mb: 0.5 }}
      />,
    ],
    Assigned: [
      <MKBadge
        variant="contained"
        color="primary"
        badgeContent="Assigned"
        container
        sx={{ mb: 0.5 }}
      />,
    ],
    Working: [
      <MKBadge
        variant="contained"
        color="info"
        badgeContent="Working"
        container
        sx={{ mb: 0.5 }}
      />,
    ],
    Completed: [
      <MKBadge
        variant="contained"
        color="success"
        badgeContent="Completed"
        container
        sx={{ mb: 0.5 }}
      />,
    ],
    Pending: [
      <MKBadge
        variant="contained"
        color="error"
        badgeContent="Pending"
        container
        sx={{ mb: 0.5 }}
      />,
    ],
  };
  // console.log("status", status);
  return (
    <MKBox
      key={id}
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor="secondary"
      borderRadius="xl"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
    >
      {image && (
        <MKAvatar
          src={image}
          alt={name}
          variant="rounded"
          size="lg"
          shadow="md"
          sx={{ mt: -5, mb: 1 }}
        />
      )}
      <MKBox lineHeight={1}>
        <MKTypography
          display="block"
          variant={image ? "button" : "h6"}
          fontWeight="bold"
          color={color === "transparent" || color === "light" ? "dark" : "white"}
          mb={0.5}
        >
          {id}- &nbsp;
          {name} &nbsp;
          {statuses[status]}
        </MKTypography>
        <MKTypography
          variant="body2"
          color={color === "transparent" || color === "light" ? "text" : "white"}
          my={0.5}
        >
          {project}
        </MKTypography>
        <MKTypography
          variant={image ? "caption" : "button"}
          fontWeight="regular"
          lineHeight={1}
          color={color === "transparent" || color === "light" ? "text" : "white"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Icon>schedule</Icon>&nbsp;
          {date}
        </MKTypography>
      </MKBox>

      <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        {description}
      </MKTypography>
    </MKBox>
  );
}

// Setting default values for the props of DefaultReviewCard
DefaultReviewCard.defaultProps = {
  color: "transparent",
  image: "",
};

// Typechecking props for the DefaultReviewCard
DefaultReviewCard.propTypes = {
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default DefaultReviewCard;
