// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios from "axios";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import MKTypography from "components/MKTypography";
// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import { Domain } from "Domain";
import { useEffect, useState } from "react";

function Presentation({ setLoggedIn }) {
  const [userDetails, SetUserDetails] = useState([]);
  const [CurrentTicket, setCurentTicket] = useState(0);
  useEffect(() => {
    SetUserDetails(JSON.parse(window.localStorage.getItem("user_details")));
  }, [CurrentTicket]);
  axios({
    method: "post",
    url: Domain + "/GetCurrentTicket.php",
    config: { headers: { "Content-Type": "multipart/form-data" } },
  })
    .then(function (response) {
      //handle success
      console.log("Current Ticket", response);
      if (response.data.success) {
        setCurentTicket(response.data.data.Current_Ticket);
      } else {
        console.log("failed");
      }
    })
    .catch(function (response) {
      //handle error
      setDeleted(true);
      console.log(response);
    });
  return (
    <>
      <DefaultNavbar
        routes={routes}
        setLoggedIn={setLoggedIn}
        action={{
          type: "internal",
          route: "/sign-in",
          label: "Logout",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters CurrentTicket={CurrentTicket} />

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                title="Previous Tickets"
                description="View your prevous tickets. Know their statuses and solutions "
                action={{
                  type: "internal",
                  route: "/previous-tickets",
                  label: "View Tickets",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                title="Raise a New Ticket"
                description="Raise a Ticket to infrom us your issues and get solutions."
                action={{
                  type: "internal",
                  route: "/raise-new-ticket",
                  label: "Raise Ticket",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Card>
      <MKTypography align="center" variant="body2" color="text" mb={2}>
        Logged in as - {userDetails.FullName} , {userDetails.Email}
      </MKTypography>
    </>
  );
}

export default Presentation;
