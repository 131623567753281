import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import Button from "components/MKButton";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Table } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
// core components
import styles from "./tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable({
  tableHead,
  tableData,
  tableHeaderColor,
  setEdit,
  setDelete,
  loading,
}) {
  const classes = useStyles();
  return (
    <div className={classes.tableResponsive}>
      {loading ? (
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={1}>
            loader
          </Grid>
        </Grid>
      ) : (
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}

          <TableBody>
            {tableData.map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  {prop.Image && (
                    <img className={classes.tableCellImage} src={"Domain" + prop.Image} alt=".." />
                  )}

                  {Object.values(prop).map((props, key) => {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        {props}
                      </TableCell>
                    );
                  })}
                  <Button
                    onClick={() => setEdit(prop.Id)}
                    simple
                    justIcon
                    size="lg"
                    color="success"
                  >
                    <Edit />
                  </Button>
                  <Button
                    onClick={() => setDelete(prop.Id)}
                    simple
                    justIcon
                    size="lg"
                    color="danger"
                  >
                    <Close />
                  </Button>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  setEdit: PropTypes.any,
  setDelete: PropTypes.any,
  loading: PropTypes.bool,
};
