import React from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Domain } from "Domain";

function SignInBasic({ setLoggedIn, SetUserType, SetUserDetails }) {
  console.log("setLoggedIn Sign In", setLoggedIn);
  const [emailError, setEmailError] = React.useState(false);
  const [PasswordError, setPasswordError] = React.useState(false);
  const [invalidCredentials, setInvalidCredentials] = React.useState(false);
  //Data variable
  const [data, setData] = React.useState({
    Email: "",
    Password: "",
  });
  //Handle data function
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  //Function for Validating fields
  function ValidateFields() {
    if (data.Email == "") {
      return false;
    } else if (data.Password == "") {
      return false;
    } else return true;
  }

  function LoginFunction() {
    if (ValidateFields) {
      setInvalidCredentials(false);
      //API calling for loggin
      let formData = new FormData();
      formData.append("Email", data.Email);
      formData.append("Password", data.Password);
      axios({
        method: "post",
        url: Domain + "/LoginCheck.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Login Data", response);
          if (response.data.success) {
            setData({
              Email: "",
              Password: "",
            });
            setLoggedIn(true);
            SetUserType("User");
            SetUserDetails(response.data.data);
            // console.log("===========Logged in", response.data.data);
          } else {
            setInvalidCredentials(true);

            console.log("failed");
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Error", response);
        });
    } else {
      setInvalidCredentials(true);
    }
  }
  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Login
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                {invalidCredentials ? (
                  <>
                    {" "}
                    <MKTypography variant="button" color="error">
                      Invalid Email or Password
                    </MKTypography>
                  </>
                ) : (
                  <>
                    <MKTypography variant="button" color="text">
                      Login using your Email and Password
                    </MKTypography>
                  </>
                )}

                <MKBox component="form" role="form" mt={1}>
                  <MKBox mb={2}>
                    <MKInput
                      error={emailError}
                      type="email"
                      label="Email"
                      fullWidth
                      onChange={(e) => HandleData(e)}
                      value={data.Email}
                      id="Email"
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      error={PasswordError}
                      type="password"
                      label="New Password"
                      fullWidth
                      onChange={(e) => HandleData(e)}
                      value={data.Password}
                      id="Password"
                    />
                  </MKBox>

                  <MKBox mt={4} mb={1}>
                    <MKButton onClick={LoginFunction} variant="gradient" color="info" fullWidth>
                      LOGIN
                    </MKButton>
                  </MKBox>
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MKTypography
                        component={Link}
                        to="/sign-up"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem"></MKBox>
    </>
  );
}

export default SignInBasic;
