import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
// core components
import CustomInput from "./CustomInput.js";
import Button from "components/MKButton";

const customFileInputStyle = {
  inputFile: {
    opacity: "0",
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "-1",
  },
  inputFileWrapper: {
    "& button svg": {
      color: "inherit",
    },
  },
};
const useStyles = makeStyles(customFileInputStyle);
export default function CustomFileInput(props) {
  const [fileNames, setFileNames] = React.useState("");
  // eslint-disable-next-line
  // useEffect(() => {
  //   console.log("file:" + files);
  // }, [files]);

  let hiddenFile = React.createRef();
  const onFocus = (e) => {
    hiddenFile.current.click(e);
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // files is the file/image uploaded
    // in this function you can save the image (files) on form submit
    // you have to call it yourself
  };
  const addFile = (e) => {
    let fileNames = "";
    let files = e.target.files;
    for (let i = 0; i < e.target.files.length; i++) {
      fileNames = fileNames + e.target.files[i].name;
      if (props.multiple && i !== e.target.files.length - 1) {
        fileNames = fileNames + ", ";
      }
    }

    setFileNames(fileNames);
    if (props.onChange) {
      props.onChange(files, fileNames);
    }
    props.setFiles(files);
  };

  const { id, endButton, startButton, inputProps, formControlProps, multiple, saved } = props;
  const classes = useStyles();
  if (inputProps && inputProps.type && inputProps.type === "file") {
    inputProps.type = "text";
  }
  let buttonStart;
  let buttonEnd;
  if (saved) {
    // console.log("saved: ", saved, "filename:", fileNames);
  }
  if (startButton) {
    buttonStart = (
      <Button {...startButton.buttonProps}>
        {startButton.icon !== undefined ? startButton.icon : null}
        {startButton.text !== undefined ? startButton.text : null}
      </Button>
    );
  }
  if (endButton) {
    buttonEnd = (
      <Button {...endButton.buttonProps}>
        {endButton.icon !== undefined ? endButton.icon : null}
        {endButton.text !== undefined ? endButton.text : null}
      </Button>
    );
  }
  return (
    <div className={classes.inputFileWrapper}>
      <input
        type="file"
        className={classes.inputFile}
        multiple={multiple}
        ref={hiddenFile}
        onChange={addFile}
      />
      <CustomInput
        id={id}
        formControlProps={{
          ...formControlProps,
        }}
        inputProps={{
          ...inputProps,
          onClick: onFocus,
          value: fileNames,
          endAdornment: buttonEnd,
          startAdornment: buttonStart,
        }}
      />
    </div>
  );
}

CustomFileInput.defaultProps = {
  multiple: false,
};

CustomFileInput.propTypes = {
  saved: PropTypes.bool,
  setFiles: PropTypes.any,
  id: PropTypes.string,
  endButton: PropTypes.object,
  startButton: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  multiple: PropTypes.bool,
  // it is a function from which you can get the file that was uploaded
  // more can be read here: https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/64 and here: https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/37
  onChange: PropTypes.func,
};
