import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import styles from "./customInputStyle.js";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(styles);

export default function SingleSelect({ placeholder, Options, setValue, noOptionMessage }) {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(null);
  const handleChange = (e) => {
    setValue({
      Id: e.value,
      label: e.label,
    });
    setSelected(e);
  };

  return (
    <Select
      styles={classes}
      value={selected}
      noOptionsMessage={() => noOptionMessage}
      isSearchable
      placeholder={placeholder}
      options={Options}
      onChange={handleChange}
    />
  );
}
SingleSelect.propTypes = {
  placeholder: PropTypes.string,
  Options: PropTypes.array,
  setValue: PropTypes.any,
  data: PropTypes.any,
  noOptionMessage: PropTypes.any,
  formControlProps: PropTypes.any,
};
