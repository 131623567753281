// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import axios from "axios";
import { Domain } from "Domain";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/city-profile.jpg";
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
import Container from "@mui/material/Container";
import { useEffect, useState } from "react";

function ContactUs() {
  const [ticekts, setTicekts] = useState([]);
  const [userDetails, SetUserDetails] = useState([]);
  useEffect(() => {
    SetUserDetails(JSON.parse(window.localStorage.getItem("user_details")));
  }, []);
  useEffect(() => {
    let formData = new FormData();

    formData.append("UserId", userDetails.id);
    axios({
      method: "post",
      url: Domain + "/GetTicketsByUserId.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Working Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            // console.log("success", response.data.data);
            setTicekts(response.data.data);
          }
        } else {
          console.log("failed");
          setTicekts(null);
        }
      })
      .catch(function (response) {
        //handle error
        console.log("failed db error");
        console.log(response);
      });
  }, [userDetails.id]);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "Internal",
          route: "/Home",
          label: "Home",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="10rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKTypography variant="h3" color="white" mt={10} ml={5} mr={5}>
            Previous Ticket Details here!!
          </MKTypography>
        </MKBox>
        <Container>
          <Grid container spacing={2} sx={{ mt: 8 }}>
            {ticekts != null ? (
              <>
                {ticekts.map((ticket) => (
                  <Grid key={ticket.id} item xs={12} md={6} lg={6}>
                    <DefaultReviewCard
                      style={{ maxHeight: "50px" }}
                      key={ticket.id}
                      id={ticket.id}
                      color="info"
                      name={ticket.Subject}
                      date={ticket.CreateDate}
                      project={ticket.Project}
                      status={ticket.Status}
                      description={ticket.Description}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <>No Tickets Raisd yet</>
            )}
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default ContactUs;
